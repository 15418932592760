<template>
  <div class="comp-tabbar">
    <div class="tabbar-fixed">
      <div
        @click="goToPage(item, index)"
        v-for="(item, index) in tabbarListdata"
        :key="index"
        class="tabbar-item"
      >
        <div class="item-img">
          <img
            v-if="activeIndex == index"
            :src="require(`../../assets/images/new/${item.icon}.png`)"
            alt=""
          />
          <img
            v-else
            :src="require(`../../assets/images/new/${item.activeIcon}.png`)"
            alt=""
          />
        </div>
        <div
          class="item-title"
          :class="{ 'item-title-active': activeIndex == index }"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["user", "global_info"]),
    tabbarListdata() {
      let tabbarList = [];
      if (this.global_info.ranking_open == "1") {
        tabbarList = [
          {
            icon: "tabbar1",
            activeIcon: "tabbar6",
            title: "首页",
            href: "/Home",
          },
          {
            icon: "tabbar4",
            activeIcon: "tabbar9",
            title: "Roll房",
            href: "/Roll",
          },
          {
            icon: "tabbar5",
            activeIcon: "tabbar10",
            title: "我的",
            href: "/About",
          },
          {
            icon: "tabbar11",
            activeIcon: "tabbar12",
            title: "排行榜",
            href: "/RankList",
          },
        ];
      }else{
         tabbarList = [
          {
            icon: "tabbar1",
            activeIcon: "tabbar6",
            title: "首页",
            href: "/Home",
          },
          {
            icon: "tabbar4",
            activeIcon: "tabbar9",
            title: "Roll房",
            href: "/Roll",
          },
          {
            icon: "tabbar5",
            activeIcon: "tabbar10",
            title: "我的",
            href: "/About",
          },
          
        ];
      }

      return tabbarList
    },
  },
  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      // tabbarList: [
      //   {
      //     icon: "tabbar1",
      //     activeIcon: "tabbar6",
      //     title: "首页",
      //     href: "/Home",
      //   },
      //   // {
      //   //   icon: 'tabbar2',
      //   //   activeIcon: 'tabbar7',
      //   //   title: '玩法',
      //   //   href: '/Lucky'
      //   // },
      //   // {
      //   //   icon: 'tabbar3',
      //   //   activeIcon: 'tabbar8',
      //   //   title: '商城',
      //   //   href: '/Mall'
      //   // },
      //   {
      //     icon: "tabbar4",
      //     activeIcon: "tabbar9",
      //     title: "Roll房",
      //     href: "/Roll",
      //   },
      //   {
      //     icon: "tabbar5",
      //     activeIcon: "tabbar10",
      //     title: "我的",
      //     href: "/About",
      //   },
      //   {
      //     icon: "tabbar11",
      //     activeIcon: "tabbar12",
      //     title: "排行榜",
      //     href: "/RankList",
      //   },
      // ],
    };
  },
  methods: {
    goToPage(item) {
      if (item.href == "/About") {
        if (!this.user.id) {
          this.$message({
            message: "还未登录，请登录！",
            type: "error",
          });
        } else {
          this.$router.push(item.href);
        }
      } else {
        this.$router.push(item.href);
      }
    },
  },
};
</script>

<style lang="scss">
.comp-tabbar {
  width: 100%;
  height: 0.6rem;
  background: rgba(0, 0, 0, 0);
  .tabbar-fixed {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0.6rem;
    z-index: 88;
    background: url(../../assets/images/new/tabbar-bg.png) no-repeat center;
    background-size: 100% 100%;
    // background: blue;
    padding: 0.09rem 0.24rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .tabbar-item {
      width: 0.4rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .item-img {
        width: 0.193rem;
        height: 0.193rem;
        margin-bottom: 0.04rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-title {
        font-size: 0.12rem;
        color: #515185;
      }
      .item-title-active {
        font-size: 0.12rem;
        font-weight: 500;
        color: #7575be;
      }
    }
  }
}
</style>
